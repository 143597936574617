@import "./fonts/fonts.css";

 .content {
    margin-top: 20px;
    margin-left: 8%;
    margin-right: 8%;
} 

body {
   
    background: #e0e1dd;
}

